// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#query-analytics-details {
  background-color: #161719;
}
#antd .ant-tabs-ink-bar {
  background: #79a70a;
  background: linear-gradient(to right, #f79e05 0%, #8f5408 100%);
  top: 1px !important;
}
#antd .ant-tabs-tab-disabled span {
  color: gray !important;
}
#antd .ant-select-arrow {
  color: white;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #1890ff !important;
}
#antd .ant-table-thead {
  height: 40px;
}
`, "",{"version":3,"sources":["webpack://./shared/style.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAEA;EAEI,mBAAA;EACA,+DAAA;EACA,mBAAA;AADJ;AAHA;EAQI,sBAAA;AAFJ;AANA;EAWI,YAAA;AAFJ;AAWA;;EAYE,yBAAA;AAnBF;AAsBA;EACE,YAAA;AApBF","sourcesContent":["#query-analytics-details {\n  background-color: rgb(22, 23, 25);\n}\n\n#antd {\n  .ant-tabs-ink-bar {\n    background: #79a70a;\n    background: linear-gradient(to right, #f79e05 0%, #8f5408 100%);\n    top: 1px !important;\n  }\n\n  .ant-tabs-tab-disabled span {\n    color: gray !important;\n  }\n  .ant-select-arrow {\n    color: white;\n  }\n}\n\n.overview-content-column {\n  @tooltip-bg: #161719;\n}\n\n// TODO: remove hack, need to find out why arrow highlight doesn't work\n.ant-table-thead\n  > tr\n  > th\n  .ant-table-column-sorter\n  .ant-table-column-sorter-inner\n  .ant-table-column-sorter-up.on,\n.ant-table-thead\n  > tr\n  > th\n  .ant-table-column-sorter\n  .ant-table-column-sorter-inner\n  .ant-table-column-sorter-down.on {\n  color: rgb(24, 144, 255) !important;\n}\n\n#antd .ant-table-thead {\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
